$not-selected-background-color: lightgray;
$selected-text-color: #32c5ff;

$border: 1px solid #d5cac9;
$border-hover: 2px solid #63cdf5;

.positioning-tab {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.positon-preset-button {
  margin: 20px;
}

.template-type-selection-tabs {
  height: 3em;
  line-height: 3em;

  display: flex;
  flex-direction: row;

  > .template-type-tab {
    flex: 1;

    text-align: center;

    background: white;

    &:not(.selected) {
      background: $not-selected-background-color;
      opacity: 0.9;
    }

    &:hover {
      cursor: pointer;
      background: white;
    }
  }
}

div.asset-position-card {
  border: 2px solid green;

  width: 100%;
  border: $border;
  margin-bottom: 0.5em;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  > div.asset-position-card-info {
    position: relative;
    padding: 5px;
  }

  > div.icons-edit-overlay {
    &:hover {
      width: 100%;
      height: 100%;
    }
    padding: 0 0.5em 1em 0.5em;

    position: relative;
    top: 50%;
    transform: translateY(-50%);
    z-index: 100;

    > div.asset-position-card-buttons {
      display: flex;
      justify-content: space-around;
      align-items: center;
    }
  }
}
