.container {
  padding-top: 24px;

  display: flex;
  flex-direction: column;
  gap: 10px;

  .instruction {
    padding-left: 2em;
  }

  .requiredError {
    margin-top: 4px;
    color: #ff4d4f;

    ul {
      padding-left: 2em;
    }
  }
}

.readonlyContainer {
  padding-top: 0;

  .instruction {
    padding-left: 0;
  }
}

.locked {
  background-color: #f5f5f5;

  >* {
    color: #bcbcbc;
  }
}

.smart {
  background-color: #e6f8ff;
}

.readonly {
  background-color: white;

  >* {
    color: rgba(0, 0, 0, 0.85);
  }
}

.readonlyTable {
  border: 1px solid rgba(0, 0, 0, 0.15);

  tr {
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  }
}

.table {
  tr {
    height: 38px;

    td {
      padding: 9px 14px;
    }
  }
}
