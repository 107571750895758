.contentParagraphs {
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  color: #000000;
}

.contentQuestions {
  font-size: 15px;
  line-height: 22px;
  font-weight: 700;
  color: #000000;
}