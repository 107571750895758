.footer {
  display: flex;
  justify-content: flex-end;
}

.content {
  display: grid;
  grid-template-columns: 3fr 7fr;
  height: 100%;
}

.layers {
  padding: 1em 1em 1em 0;
  overflow: auto;
}

.tabs {
  :global .ant-tabs-content-holder {
    overflow: auto;
  }
}

.drawer {
  display: grid;
  grid-template-columns: 464px 3fr;
  height: 100%;
  gap: 10px;
}
