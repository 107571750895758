div.addTagDropdownContainer {
  padding: 0.5em;

  &:hover {
    background: #e6f7ff;
    cursor: pointer;
  }
}

span.addTemplateTagContainerSpan {
  > span {
    font-weight: bold;
    font-size: 1.1rem;

    display: inline-block;
    padding: 0 0.3em;
  }
}

.uploadHtmlFileError {
  color: red;
}
