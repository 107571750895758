:root {
  --aflac-blue: #00a7e1;
  --aflac-dark-blue: #0070a9;
  --primary-text-color: #ababab 0.85;
  --secondary-text-color: rgba(0, 0, 0, 0.45);
}

.aflacDarkBlue {
  color: var(--aflac-dark-blue);
}

.aflacBlue {
  color: var(--aflac-blue);
}

.homepage {
  background-color: white;
  min-height: 100vh;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  gap: 60px;
  width: 1150;
}

.footerWrapper {
  display: flex;
  flex-direction: column;
}
