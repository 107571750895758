.container {
  width: 100%;
  height: calc(100% - 80px);

  display: flex;
  justify-content: center;
  align-items: center;

  .feedback {
    background-color: #f5f9fc;
    border-radius: 10px;
    padding: 48px 0;
    padding-left: 64px;
    position: relative;
    width: 952px;
    height: 254px;

    .message {
      display: flex;
      flex-direction: column;
      gap: 24px;
    }

    > img {
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
}
