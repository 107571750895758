.toggleContainer {
  display: flex;
  gap: 32px;
  cursor: pointer;
  span {
    padding: 12px 0;
    &:first-of-type {
      margin-left: 12px;
    }
  }
  .selected {
    color: #00a7e1;
    border-bottom: 2px solid #00a7e1;
  }
}

.table {
  padding: 0;
  padding-left: 70px;
  overflow: hidden;
  :global .ant-table-body {
    // Antd puts this scroll on the element itself so there's no other way to override it
    overflow-x: hidden !important;
  }
}

.row {
  overflow: hidden;
  background-color: #fbfbfb;
  td {
    padding: 0;
  }
}
