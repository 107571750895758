.SiderMenu {
  overflow-x: scroll;
  height: 100%;

  > div {
    display: flex;
    flex-direction: column;
  }
}

.footer {
  flex: 3;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 239px;
  align-items: center;
  background-color: white;
}

.selectedTitle {
  margin-top: 41px;
  font-size: 17px;
  line-height: 22px;
  font-weight: 400;
  color: #000000;
}