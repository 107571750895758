.menuTitle {
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  color: rgba(0,0,0,0.45);
}

.titleMenuCtn {
  display: flex;
  flex-direction: column;
  margin-bottom: 17px;

  > a {
    color: black;

    > button {
      border: none;
      text-align: start;
      padding: 5px 0;
      background-color: transparent;
      cursor: pointer;
      width: 100%;
      padding: 5px 12px;
      &:hover {
        font-weight: 600;
        background-color: #E6F7FF;
      }
    }
  }
}