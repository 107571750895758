.toolbar-table {
  display: flex;
  padding: 0 8px 12px 0;
  justify-content: space-between;
  .toolbar-left-group,
  .toolbar-right-group {
    display: flex;
    flex-wrap: nowrap;
  }
  .new-btn-toolbar {
    border-radius: 19px;
    margin-right: 10px;
  }

  .ad-format-buttons {
    display: flex;
  }

  .ad-format-buttons,
  .action-btn-toolbar,
  .expand-btn-toolbar,
  .column-btn-toolbar,
  .table-btn-toolbar,
  .enabled-text,
  .disabled-text {
    margin-left: 4px;
  }

  .table-btn-toolbar,
  .card-btn-toolbar {
    background-color: #f5f5f5;
    color: #d9d9d9;

    &--selected {
      background-color: #ffffff;
      color: #40a9ff;
      border-color: #40a9ff;
    }
  }

  .disabled-text {
    color: rgba(0, 0, 0, 0.5);
  }
  .action-switch-toolbar {
    margin-left: 4px;
    display: flex;
    align-items: center;
  }
  .sort-cascader {
    display: flex;
    align-items: center;
    svg {
      margin-right: 0.5em;
    }
  }
  .toolbar-left-group {
    margin-right: 4px;
  }
}

.ant-cascader-menu {
  height: 100%;
}
