.container {
  max-width: 1150px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto;
  gap: 36px;
  margin-bottom: 60px;
}

.cardContainer {
  width: calc(50% - 37.5px);
}
