.bodyRight {
  display: flex;
  flex-direction: column;
  background-color: #f5f5f5;
  padding: 0 1em 1em;
  overflow-y: auto;
  height: 100%;
}

.bodyLeft {
  margin-left: -20px;
}
