.navTemplateItems {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.navTemplateButton {
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navTemplateArrow {
  height: 14px;
  width: 14px;
}

.showVariablesContainer {
  margin-left: 0.5em;
}
.switchContainer {
  margin-left: 0.5em;
}
