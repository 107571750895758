.container {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .section {
    display: flex;
    gap: 8px;
    flex-direction: column;

    .file-info,
    .email-info {
      display: flex;
      gap: 8px;
      align-items: center;
    }
  }
}
