.footerContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;
  padding: 0 145px;
  background: #f5f9fc;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 80px;
  margin-top: auto;
}

.linksWrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.linksContainer {
  display: flex;
  gap: 24px;
}

.linkText {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.copyrightContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.aflacLogo {
  width: 72px;
}
