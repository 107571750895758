.inputParameters {
    :global {
	.ant-form-item {
	    width: 100%;
	    padding: 0 0.3em;
	}

	.form-group {
	    display: flex;
	    justify-content: space-between;
	}
    }
}
