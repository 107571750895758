.container {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;

  .content {
    width: 100%;
    height: 100%;
    padding: 20px;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .save {
    border-top: 1px solid #e0e0e0;
    width: 100%;
    padding: 15px;

    .bottombox {
      padding: 10px;
      background-color: #f8f8f8;
      border-radius: 3px;
      border: 1px solid #e0e0e0;
    }
  }

  .topbox {
    padding: 10px;
    background-color: #f8f8f8;
    border-radius: 3px;
    border: 1px solid #e0e0e0;
    margin-bottom: 15px;
  }

  .checkbox {
    display: flex;
    margin: 1em 0;

    .tooltipSpan {
      margin-left: 0.5em;
      color: rgba(0, 0, 0, 0.45);
    }
  }

}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 1em;
}

.savedFilterOption {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  >.deleteButton {
    display: block;
  }
}
