.statusDescription {
  display: flex;
  justify-content: flex-end;
}
.downloadBtn {
  color: #1890ff;
  cursor: pointer;
}
.errorDescription {
  text-align: right;
  .errorText {
    padding-right: 20px;
  }
}
.notificationIcon {
  :global {
    color: #1890ff;
  }
}
