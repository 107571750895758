.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 1150px;
  margin: 10px;
}

.content {
  display: flex;
  padding: 10px 0px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}

.section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  width: 1141px;
  height: 100%;
}

.headerTitle {
  display: flex;
  align-items: center;
  align-self: stretch;
  gap: 10px;
}

.accounts {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 10px;
  align-self: stretch;
  flex-wrap: wrap;
}

.accountList {
  display: flex;
  width: 1150px;
  align-items: "flex-start";
  align-content: "flex-start";
  gap: 16px 25px;
  flex-wrap: wrap;
}
