.loader {
  width: 100%;
  height: 15em;

  position: relative;

  .spinner {
    position: absolute;

    left: 50%;
    top: 50%;

    transform: translate(-50%, -50%);
  }
}

.templateListCollapse {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;

  align-items: center;
}

.toolbar {
  flex-grow: 1;
  flex-wrap: wrap;
  align-items: center;
  margin: 1em 1em 3em 1em;
}

.toolbarClients {
  flex-grow: 1;
  min-width: 300;
  margin: 0.5em;
  justify-content: space-between;
}

.toolbarSearch {
  flex-grow: 1;
  min-width: 200px;
}

.buttonToolbar {
  padding: 0 8px 0 12px;
}

.filtersContainer {
  width: "100%";
  margin-top: 12px;
  margin-bottom: 16px;
  background: #ffffff;
}

.cardTitle {
  display: flex;
  flex-direction: column;
}

.dashboardName {
  color: #1890FF;
}
.accountName {
  color: rgba(0,0,0,0.45);
}

.iframeContainer {
  text-align: center;
  width: 100vw;
  height: 100%;
  display: flex;
}

.spinner {
  display: flex;
  height: 100%;

  :global {
    .ant-spin-container {
      display: flex;
      flex-grow: 1;
    }
  }
}

.dashboard {
  display: flex;
  height: 100%;
  flex-grow: 1;

  iframe {
    width: 100%;
    height: 100%;
  }
}

.detailInfo {
  display: flex;
  width: 30%;
  height: 40px;
  align-items: center;
}

.detailHeader {
  display: flex;
  margin: 0 1em;
  justify-content: space-between;
  align-items: center;
}

.detailText {
  margin-left: 3em;
}

.imgContainer {
  display: flex;
  width: 100%;
  height: 300px;
  overflow: hidden;
  justify-content: center;
  align-items: center;
}
