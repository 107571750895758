.container {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;

  .tabsContainer {
    flex: initial;
    width: 664px;

    .tabs {
      width: 100%;

      >*:first-of-type {
        width: 200px;
        padding-top: 16px;
      }

      >*:last-of-type {
        height: 100%;
        overflow-x: hidden;
        overflow-y: auto;
      }
    }
  }

  .previewContainer {
    flex: 1;
    position: relative;
    background-color: #f5f5f5;
  }

  .content {
    width: 100%;
    height: 100%;
  }

  .componentsTabs {
    flex: initial;
    width: 100%;
    padding-top: 24px;
    padding-right: 24px;

    .componentsTabsPane {
      width: 100%;

      >*:nth-child(2) {
        height: 100%;
        overflow-x: hidden;
        overflow-y: auto;
      }
    }
  }
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .cta {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 1em;
  }
}

.tabs {
  width: 100%;
  height: 100%;

  .ant-tabs-content {
    height: 100%;
  }

  .tabPane {
    height: 100%;
  }
}

.pdfTip {
  opacity: 0.45;

  display: flex;
  flex-direction: column;
  gap: 1em;
}
