.container {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 365px;
  width: 240px;
  background: white;
  border-radius: 12px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  transition: height 0.2s ease-in-out;

  .checkbox {
    position: absolute;
    z-index: 2;
    top: 12px;
    left: 12px;
    visibility: hidden;
  }

  .checkbox.visible {
    visibility: visible;
  }

  &:hover {
    .checkbox {
      visibility: visible;
    }
    height: 400px;

    .actions {
      opacity: 1;
    }
  }

  .status {
    position: absolute;
    z-index: 2;
    padding: 3px 8px;
    background: white;
    border-radius: 4px;
    top: 12px;
    right: 12px;

    > .badge > span:first-child {
      width: 12px;
      height: 12px;
    }
  }

  .imageContainer img {
    height: 240px;
    width: 100%;
    object-fit: cover;
  }

  .infoContainer {
    padding: 0 20px;

    .name {
      display: block;
      font-weight: bold;
    }

    .details {
      font-size: 12px;
      color: #8c8c8c;
      margin-bottom: 10px;
    }

    .divider {
      background: #8c8c8c;
      margin: 0;
    }
  }

  .actions {
    border-top: 1px solid rgb(217, 217, 217);
    border-radius: 0 0 12px 12px;
    margin-top: auto;
    display: flex;
    opacity: 0;
    justify-content: space-evenly;
    transition: opacity 0.2s ease-in-out;

    > button {
      border: none;
      border-radius: 0;

      &:hover {
        background: #f5f5f5;
        box-shadow: 0 -1px 0 #40a9ff;
        color: initial;
      }
    }
  }
}

.hoverBorder {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  border: 2px solid transparent;
  transition: border 0.2s ease-in-out;
  pointer-events: none;
  z-index: 2;
}

.selected {
  border: 2px solid #1890ff;
}
