.seeAll {
  color: var(--aflac-dark-blue);
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  margin-bottom: 24px;
  flex: 1 0 0;
}

.header {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

.headerTitle {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
}

.description {
  align-self: stretch;
  color: var(--secondary-text-color);
  font-size: 16px;
  line-height: 140%;
}

:global {
  .ant-row {
    .ant-col {
      .ant-list-item {
        display: flex;
        cursor: pointer;
        height: 80px;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        flex: 1;
        border-radius: 8px;
        border: 1px solid rgba(0, 0, 0, 0.05);
        background: #f5f9fc;
      }
    }
  }
}

.errorResult {
  margin: 0 auto;
}
