.media {
  width: 100%;
  height: 100%;
}

.previewContainer {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
}
