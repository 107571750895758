.offer-selection {
  width: 100%;
  max-height: 500px;
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;

  .offer-type-selection {
    li {
      display: flex;
      align-items: center;
      margin: 0.5em 0;
    }
  }

  > .offer-selection-choices {
    .ant-select-multiple .ant-select-selector .ant-select-selection-item {
      min-height: 24px;
    }
  }

  .purchase-type-select {
    width: 100%;

    ul {
      li {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }
  }
}
