.footer {
  display: flex;
  justify-content: flex-end;
}

.contentAdmin {
  display: grid;
  height: 100%;

  max-height: 77vh;
}

.contentAgent {
  height: 100%;

  :global(.ant-tabs-content) {
    height: 100%;
  }
}

.layers {
  padding: 1em 1em 1em 0;
  overflow: auto;
}

.tabs {
  :global .ant-tabs-content-holder {
    overflow: auto;
  }
  :global(.ant-tabs-content) {
    height: 100%;
  }
}
