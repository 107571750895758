.featureCard {
  display: flex;
  padding: 36px 51px;
  align-items: center;
  align-content: center;
  flex: 1 0 0;
  align-self: stretch;
  flex-wrap: wrap;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  background: #f5f9ff;
}

.iconCircle {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--aflac-dark-blue);
  border-radius: 50%;
  width: 70px;
  height: 70px;
  color: white;
  font-size: 33px;
}

.featureCardContent {
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  align-items: flex-start;
}

.featureCardText {
  display: flex;
  width: 365px;
  flex-direction: column;
  align-items: flex-start;
}
