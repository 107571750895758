.feed-output-container {
  > .feed-config-title {
    font-weight: 600;
    margin: 1rem;
  }

  > .summary {
    display: flex;
    flex-direction: row;
    align-items: center;

    > .left {
      flex: 1;
      flex-direction: row;
      display: flex;
      > .required-column-warning {
        color: red;
      }

      > .refresh-button {
        margin-left: 1.5em;
      }
    }

    > .right {
      flex: initial;
      display: flex;
      flex-direction: row;
      align-items: center;

      > * {
        flex: 1;
        margin: 0 2px;
      }

      > .total-records {
        margin-right: 2em;
      }
    }
  }

  > .data-list {
    margin: 1em 0;

    .smart-column-header {
      display: flex;
      flex-direction: row;
      align-items: center;

      > .title {
        display: inline-block;
        margin-left: 5px;
        margin-right: 20px;
      }
    }
  }
}

.smart-column-popover {
  > .title {
    margin-left: 10px;
    text-transform: uppercase;
  }
}

.error-message {
  display: flex;
  flex-direction: row;

  align-items: center;

  color: red;
  > * {
    flex: initial;
    display: inline-block;
  }

  > .message {
    margin-left: 0.5em;
  }
}

.mark-as-export-list-container {
  ul {
    width: auto;
    max-height: 15em;
    overflow-x: hidden;
    overflow-y: auto;

    list-style: none;
    padding: 0.7em 0;
    margin: 0;

    > li {
      margin: 0.5em 0;

      &.warning {
        color: red;
        padding-left: 1em;
        margin-top: 1em;
      }

      > .checkbox-container {
        display: flex;
        flex-direction: row;
        align-items: center;

        > div:first-child {
          width: 1em;
          color: red;
        }
      }
    }
  }
}

.mark-image-video-wrapper {
  width: 15em;
  height: auto;

  > .ant-select {
    width: 100%;
  }

  > .warning {
    margin-top: 0.7em;
    color: red;
  }
}

.pagination-container {
  width: 100%;
  display: inline-flex;
  height: 3em;
  justify-content: center;
  margin-top: 1em;
  > .displaying-desc-container {
    margin-right: 1em;
    margin-top: 0.3em;
    font-size: medium;
  }
}
