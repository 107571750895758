.itemDetails {
  display: flex;
  height: 77px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 1 0 0;
}

.itemCell {
  display: flex;
  padding: 0px 8px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

.itemName {
  font-size: 16px;
  font-weight: 500;
  color: var(--aflac-dark-blue);
}

.itemExpiration {
  font-size: 12px;
  color: #ababab;
}

.thumbnailContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
}
