.cell {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  min-width: 110px;
  min-height: 23px;
}

.cell:hover .editIcon {
  color: #1890ff;
}

.editIcon {
  font-size: 16px;
  color: transparent;
  display: block;
  cursor: pointer;
  width: 16px;
}
