.container {
  display: flex;
  width: 1150px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 11px;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
  width: calc(20% - 14.4px);
  height: 180px;
}

.icon {
  display: flex;
  padding: 9.5px 7.5px 5.5px 9.5px;
  justify-content: center;
  align-items: center;
}

.salesCycleSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}

.salesCycleHeader {
  display: flex;
  flex-direction: column;
  width: 1157px;
  align-items: flex-start;
  padding-right: 24px;
  gap: 16px;
}

.salesCycleCards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  gap: 18px;
}

.cardContent {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
}

.cardIcon {
  font-size: 36px;
  color: #00a7e1;
}

.cardText {
  text-align: center;
}
