$padding: 0.3rem;

.advancedFilters {
  display: flex;
  justify-content: space-between;
  padding: $padding;
  color: #0091ff;
}

.filterBy {
  width: 100%;
  margin-bottom: 0.5em;

  > .title {
    padding: $padding;
  }
}
