.ruleInputContainer {
  margin-top: 0.5rem;
  display: flex;
  gap: 0.5rem;
  align-items: flex-end;

  .inputContainer {
    flex: 1;
    overflow: auto;

    .input {
      width: 100%;
    }
  }
}
