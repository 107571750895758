.container {
  width: 350px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.closeContainer {
  cursor: pointer;
  height: 100%;
  display: flex;
  margin-left: 8px;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0;

  // Antd override
  :global {
    .anticon {
      font-size: 14px;
      opacity: 0.45;
      color: #000;
    }
  }
}

// Antd override
.errorMessage > div {
  background-color: #fff1f0;
  border: 1px solid #ffccc6;
}

.successMessage {
  :global {
    div.ant-message-notice-content {
      background-color: #f6ffed;
      border: 1px solid #b7eb8f;
    }
  }
}

.warningMessage {
  :global {
    div.ant-message-notice-content {
      background-color: #fffbe6;
      border: 1px solid #ffe58f;
    }
  }
}

// Antd override
.successMessage,
.errorMessage {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;

  :global {
    span.anticon.anticon-close-circle {
      margin-right: 12px;
      top: 0;
    }
    div.ant-message-notice-content {
      width: 395px;
      padding: 0 18px;
    }
    div.ant-message-custom-content {
      width: 100%;
      display: flex;
      align-items: center;
      height: 42px;
    }
  }
}

.bigContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.warningMessage.bigNoticeDiv,
.successMessage.bigNoticeDiv,
.errorMessage.bigNoticeDiv {
  :global {
    div.ant-message-notice-content {
      width: unset;
    }
  }
}
