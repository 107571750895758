.footer {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  & > div {
    button {
      margin: 0 10px;
    }
  }
}
