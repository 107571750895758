.radioSelectionTitle {
  flex: 1;
  color: rgba(0, 0, 0, 0.45);
  font-weight: bold;
  margin-top: 2%;
}

.sectionTopSpacing {
  padding-top: 5%;
}

.exportRow {
  flex-direction: column;
  justify-content: space-between;
  row-gap: 20px;
}

.exportOptions {
  margin-left: 2%;
}

.assetBatchOptions {
  margin-top: 2%;
  margin-left: 2%;
}

.footer {
  display: flex;
  justify-content: space-between;
}

.nextBackButtonsContainer {
  display: flex;
  gap: 8px;
}

.divider {
  margin-top: 0;
  margin-bottom: 10px;
}

.checkboxContainer {
  margin-left: 12px;
}

.radioGroup {
  margin-left: 12px;
}

.exportSettingsWrapper {
  width: 100%;
  display: flex;
  gap: 130px;
}

.exportSettingsColumn {
  width: 30%;
  min-width: 120px;
}

.matchColumnsWrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.adTypeInputWrapper {
  padding-top: 4px;
}

.adTypeInput {
  width: 200px;
}

.infoIcon {
  color: #faad14;
}

.checkIcon {
  color: #52c41a;
}

.columnTitleInput {
  width: 300px;
}

.alertContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.highlightRow {
  background: #fffbe6;
}

.errorRow {
  background: #fff1f0;
}

.assetBatchNameTooltip {
  margin-left: 4px;
}

.assetBatchNameTag {
  margin-left: 18px;
}

.feedColumnCellWrapper {
  display: flex;
  align-items: center;
}

.thumbnailWrapper {
  height: 57px;
}

.thumbnail {
  display: block;
  height: 100%;
  width: auto;
}

.fillAdInfoWrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
