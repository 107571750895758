.welcomeSection {
  display: flex;
  width: 1150px;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid #eee;
  background: linear-gradient(180deg, #f5f9fc 0%, #f0f7fd 100%);
  padding: 24px 48px;
  margin: 20px 0;
}

.homepageTitle {
  font-size: 42px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  letter-spacing: -0.42px;
}

.welcomeText {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 16px;
}

.browseTemplatesButton {
  display: flex;
  width: 172px;
  height: 41px;
  padding: 6.4px 15px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background: var(--aflac-dark-blue);
  margin-top: 10px;
}

.aflacDuck {
  width: 416px;
  height: 300px;
}

.fontSize {
  font-size: 16px;
  color: black;
}
