.arrows {
  position: absolute;
  z-index: 1;
  right: 0;
  text-align-last: right;
}

.arrowsWrapper {
  display: inline-flex;
  flex-direction: column;
  vertical-align: text-top;
  width: 22px;
  margin-right: 5px;
  align-items: center;

  &:hover {
    background-color: #e8f4ff;
  }
}

.arrowUp,
.arrowDown {
  font-size: 12px;
  color: #bfbfbf;
  cursor: pointer;
}

.arrowUp {
  position: relative;
  top: 2px;
}

.arrowDown {
  position: relative;
  bottom: 2px;
}

.selectInput {
  margin-left: 4px;
  width: 190px;
}

// Antd override
.customSelect :global {
  position: relative;

  .ascend .anticon-caret-up,
  .descend .anticon-caret-down {
    color: #1890ff;
  }

  .ant-select-single .ant-select-selector .ant-select-selection-item {
    display: flex;
    align-items: center;
    margin-right: 14px;
    height: 24px;
    margin-block: auto;
    padding-left: 5px;

    &:hover {
      background-color: #e8f4ff;
      border-radius: 2px;
    }
  }
}
