.placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  min-height: 100px;
  min-width: 100px;
  max-height: 100px;
  span {
    margin-bottom: 5px;
  }
  .plusIcon {
    font-size: 20px !important;
    color: black !important;
  }
}

.dragger {
  :global .ant-upload.ant-upload-btn {
    padding: 0;
  }
}

.imageSelector {
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-gap: 10px;
  .imageLibraryPicker {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 6px;
    a {
      padding-left: 16px;
    }
  }
}

.imagePreviewMask {
  .actionButtons {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 0 20%;
  }
}
